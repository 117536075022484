import React, { useEffect, useState } from "react";
import _ from "lodash";
import { t } from "@lingui/macro";
import { toast } from "react-toastify";
import ReactFlow, { Background, BackgroundVariant, ControlButton, Controls, MiniMap, applyNodeChanges } from "reactflow";
import { Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { usePatchNodeMutation, useMoveNodeMutation } from "../hierarchyService";
import { toast_options_err } from "modules/notification/notificationMiddleware";

import { NodeActionType, remapForReactFlow } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faBorderNone } from "@fortawesome/free-solid-svg-icons";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

import GenericNode from "./nodes/GenericNode";

const customNodeTypes = {
    equipmentLink: GenericNode,
    equipmentUnlink: GenericNode
};

const DiagramFlow = (props) => {
    const { nodesInfo, org, equipments, sites, usages, diagram, disabled_diagram, rangeTime } = props;
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [dragNode, setDragNode] = useState(null);
    const [displayBackground, setDisplayBackground] = useState(false);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === "Tab") {
                e.preventDefault();
            }
        };

        // Attach the event listener to the document (or a specific container element)
        document.addEventListener("keydown", handleKeyPress);

        // Don't forget to remove the event listener when the component unmounts
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const onNodeAction = async (nodes_edges, action, extra, move_node = null) => {
                switch (action) {
                    case NodeActionType.Add:
                        await setNodes((nds) => {
                            const nodes_l = [...nds];
                            _.each(nodes_edges.nodes, (node) => {
                                const old_node_index = _.findIndex(nodes_l, { id: node.id });
                                if (old_node_index === -1) {
                                    nodes_l.push({
                                        ...node,
                                        data: {
                                            ...node.data,
                                            extra,
                                            actions: {
                                                onNodeAction
                                            }
                                        }
                                    });
                                } else {
                                    nodes_l[old_node_index] = {
                                        ...nodes_l[old_node_index],
                                        data: {
                                            ...nodes_l[old_node_index].data,
                                            node_db: node.data.node_db
                                        }
                                    };
                                }
                            });
                            return nodes_l;
                        });
                        await setEdges((eds) => {
                            const edges_l = [...eds];
                            _.each(nodes_edges.edges, (edge) => {
                                const old_edge_index = _.findIndex(edges_l, { id: edge.id });
                                if (old_edge_index === -1) {
                                    edges_l.push(edge);
                                }
                            });
                            return edges_l;
                        });
                        break;
                    case NodeActionType.Update:
                    case NodeActionType.Move:
                        await setNodes((nds) => {
                            const nodes_l = [...nds];
                            _.each(nodes_edges.nodes, (node) => {
                                const old_node_index = _.findIndex(nodes_l, { id: node.id });
                                if (old_node_index !== -1) {
                                    nodes_l[old_node_index] = {
                                        ...nodes_l[old_node_index],
                                        type: node.type,
                                        data: {
                                            ...nodes_l[old_node_index].data,
                                            node_db: node.data.node_db
                                        }
                                    };
                                }
                                if (move_node && old_node_index === -1) {
                                    //new node children add after move
                                    nodes_l.push({
                                        ...node,
                                        data: {
                                            ...node.data,
                                            extra,
                                            actions: {
                                                onNodeAction
                                            }
                                        }
                                    });
                                }
                            });
                            if (move_node) {
                                //Here we clean nodes to remove old children of move_node
                                const clean_nodes = _.filter(nodes_l, (item) => {
                                    return !_.includes(item.id, move_node);
                                });
                                return clean_nodes;
                            }
                            return nodes_l;
                        });
                        if (move_node) {
                            await setEdges((eds) => {
                                const all_edges = [...eds, ...nodes_edges.edges];
                                const clean_edges = _.chain(all_edges)
                                    .uniqBy("id") //fusion between old && new nodes
                                    .filter((item) => {
                                        if (_.includes(item.id, move_node)) {
                                            //remove all edges with link to move_node
                                            return false;
                                        }
                                        return true;
                                    })
                                    .value();
                                return clean_edges;
                            });
                        }
                        break;
                    case NodeActionType.Delete:
                        await setNodes((nds) => {
                            const deleted_nodes_ids = _.map(nodes_edges.nodes, (node) => node.id);
                            const remaining_nodes = _.filter(nds, (node) => {
                                return !_.includes(deleted_nodes_ids, node.id);
                            });
                            return remaining_nodes;
                        });
                        await setEdges((eds) => {
                            const deleted_edges_ids = _.map(nodes_edges.edges, (edge) => edge.id);
                            const remaining_edges = _.filter(eds, (edge) => {
                                return !_.includes(deleted_edges_ids, edge.id);
                            });
                            return remaining_edges;
                        });
                        break;
                    default:
                        break;
                }
            };

            const { id: diagram_id, type: diagram_type } = diagram;

            //Rewrite each nodes to add some data in node.data for ReactFlow's node usage
            const updatesNodes = _.map(nodesInfo.nodes, (node) => {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        extra: {
                            sites,
                            usages,
                            equipments,
                            diagram_id,
                            diagram_type,
                            org,
                            rangeTime,
                            disabled_diagram
                        },
                        actions: {
                            onNodeAction
                        }
                    }
                };
            });
            await setNodes(updatesNodes);
            await setEdges(nodesInfo.edges);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesInfo, disabled_diagram]); // add disabled_diagram to reload nodes with correct disable_diagram in GenericNodes

    const [updatePosition, updatePos] = usePatchNodeMutation();
    const [move, moveNode] = useMoveNodeMutation();

    useEffect(() => {
        if (updatePos.isError || moveNode.isError) {
            let error = i18n._(t`cannot change node position`);
            if (updatePos.error?.data && !_.includes(updatePos.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={updatePos.error?.data} />;
            }
            if (moveNode.error?.data && !_.includes(moveNode.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={moveNode.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [updatePos, moveNode]);

    return (
        <Segment style={{ height: "800px" }} attached>
            <ReactFlow
                fitView
                snapToGrid={true}
                snapGrid={[30, 30]}
                disableKeyboardA11y={true}
                selectionKeyCode={null} //prevent area selection
                multiSelectionKeyCode={null} //prevent multiple selection during DragNDrop
                nodeTypes={customNodeTypes}
                nodes={nodes}
                edges={edges}
                onConnect={async (connection) => {
                    const { source, target } = connection;
                    if (source === target) return;
                    const node_to_move = _.find(nodes, (node) => node.id === target);
                    if (node_to_move) {
                        const init_parent = node_to_move?.data?.node_db?.parent;
                        if (init_parent === source) return;
                        const onNodeAction = node_to_move?.data?.actions?.onNodeAction;
                        const extra_data = node_to_move?.data?.extra;
                        const action = await move({
                            diagram_id: diagram.id,
                            org,
                            start: rangeTime.start.format("YYYY-MM-DD"),
                            end: rangeTime.end.format("YYYY-MM-DD"),
                            data: { parent: source, node: target }
                        });
                        const error = _.get(action, "error", null);
                        if (!error) {
                            const nodes_edges = _.reduce(
                                _.uniqBy(action.data, "id"),
                                (res, node) => {
                                    const { node: remapNode, edge } = remapForReactFlow(node);
                                    if (edge) {
                                        res.edges.push(edge);
                                    }
                                    res.nodes.push(remapNode);

                                    return res;
                                },
                                { nodes: [], edges: [] }
                            );
                            onNodeAction && (await onNodeAction(nodes_edges, NodeActionType.Move, extra_data, target));
                        }
                    }
                }}
                onConnectStart={async (event, params) => {
                    const { nodeId } = params;
                    await setNodes((nds) => {
                        const nodes_l = [...nds];
                        return _.map(nodes_l, (node) => {
                            if (node.id !== nodeId && _.includes(node.id, nodeId)) {
                                return {
                                    ...node,
                                    style: {
                                        ...node.style,
                                        border: "2px solid var(--foundational-primary)",
                                        borderRadius: "5px",
                                        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)"
                                    }
                                };
                            }
                            return node;
                        });
                    });
                }}
                onConnectEnd={async (event) => {
                    await setNodes((nds) => {
                        const nodes_l = [...nds];
                        return _.map(nodes_l, (node) => {
                            return {
                                ...node,
                                style: _.omit(node.style, ["border", "borderRadius", "boxShadow"])
                            };
                        });
                    });
                }}
                onNodesChange={(changes) => {
                    /* In association with 'multiSelectionKeyCode/selectionKeyCode'
                    Only one node is selected so check node's type and check if it's 'remove' */
                    if (_.get(changes, "[0].type") === "remove") return;
                    setNodes((nds) => applyNodeChanges(changes, nds));
                }}
                onNodeDragStart={(event, node) => {
                    setDragNode(node);
                }}
                onNodeDragStop={async (event, node) => {
                    //prevent unnecessary request if only click on node without change position
                    const sameNodePosition = _.isEqual(node?.position, dragNode?.position);
                    if (!sameNodePosition) {
                        const {
                            position: { x: position_x, y: position_y }
                        } = node;
                        const action = await updatePosition({
                            org,
                            data: { position_x, position_y },
                            node_id: parseInt(node?.data?.node_db?.id ?? 0)
                        });
                        const error = _.get(action, "error", null);
                        if (error) {
                            //revert node position if error
                            const revert_nodes = _.map(nodes, (n) => {
                                if (n.id === node.id) {
                                    return dragNode;
                                }
                                return n;
                            });
                            setNodes(revert_nodes);
                        }
                        setDragNode(null);
                    }
                }}
                //Prevent usage of flow for non-owner
                nodesDraggable={!disabled_diagram}
                nodesConnectable={!disabled_diagram}
                proOptions={{
                    hideAttribution: true
                }}
            >
                <MiniMap zoomable pannable position="top-right" />
                <Background gap={30} variant={displayBackground ? BackgroundVariant.Dots : null} />
                <Controls position="top-left" showInteractive={false}>
                    {!disabled_diagram && (
                        <ControlButton
                            onClick={() => {
                                setDisplayBackground(!displayBackground);
                            }}
                            title="display background"
                        >
                            <div>
                                {!displayBackground && <FontAwesomeIcon icon={faBorderAll} className="icon" />}
                                {displayBackground && <FontAwesomeIcon icon={faBorderNone} className="icon" />}
                            </div>
                        </ControlButton>
                    )}
                </Controls>
            </ReactFlow>
        </Segment>
    );
};

export default DiagramFlow;
